<template>
  <!-- grid item -->
  <div v-masonry-tile class="item">
    <div class="inner">
      <router-link
        class="episode-link"
        :to="{ name: 'episode-show', params: { id: episode.id } }"
      >
        <p v-if="episode.header_image" class="image">
          <img
            :src="episode.header_image"
            :alt="episode.title"
            class="obj-fit-img"
          >
        </p>
      </router-link>
      <dl>
        <div>
          <dt class="episode">
            <strong>{{ episode.time_display }}</strong>エピソード
          </dt>
          <LikeButton
            ref="likeButton"
            :object="episode"
            object-type="EPISODE"
          />
        </div>
        <router-link
          class="episode-link"
          :to="{ name: 'episode-show', params: { id: episode.id } }"
        >
          <dt class="title">
            {{ episode.title }}
          </dt>
          <dd class="text">
            {{ episode.header }}
          </dd>
          <dd class="update">
            {{ episode.date }}
          </dd>
        </router-link>
      </dl>
      <p class="profile" :class="episode.user.profile_color">
        <router-link
          :to="{ name: 'user-show', params: { id: episode.user.id } }"
        >
          <BaseUserImg :user="episode.user" />
          <span>{{ episode.user.full_name }}</span>
        </router-link>
      </p>
      <router-link
        class="episode-link"
        :to="{ name: 'episode-show', params: { id: episode.id } }"
      >
        <ul class="hash">
          <li v-for="(tag, index) in episode.tags" :key="index">
            ＃{{ tag.name }}
          </li>
        </ul>
      </router-link>
    </div>
    <div v-if="canEdit" class="editBox">
      <ul>
        <li class="edit">
          <router-link
            v-if="!confirmDelete"
            class="episode-edit"
            :to="{ name: 'episode-edit', params: { id: episode.id } }"
          >
            編集
          </router-link>
        </li>
        <li class="delete">
          <a @click="deleteEpisode($event)">削除</a>
        </li>
        <li class="select" @click="displayChange">
          <div class="selected" :class="displayClass">
            {{ displayString }}
          </div>
          <ul
            v-show="changeDisplay"
            class="publishDropdown"
            @mouseleave="onMouseleave"
          >
            <li @click="onEpisodeDisplayChange(false)">
              非公開
            </li>
            <li @click="onEpisodeDisplayChange(true)">
              公開
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LikeButton from '@/components/LikeButton.vue'
import { authComputed } from '@/store/helpers.js'

export default {
  name: 'EpisodeCard',
  components: {
    LikeButton
  },
  props: {
    episode: {
      type: Object,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      display: null,
      deleteMode: false,
      changeDisplay: false
    }
  },
  computed: {
    ...authComputed,
    confirmDelete: function () {
      return this.deleteMode
    },
    canEdit: function () {
      return this.$store.getters.isOwner(this.episode.user.id) && this.editMode
    },
    displayString: function () {
      if (this.display) {
        return '公開'
      } else {
        return '非公開'
      }
    },
    displayClass: function () {
      if (this.display) {
        return 'publish'
      } else {
        return ''
      }
    }
  },
  created: function () {
    this.display = this.episode.display
  },
  methods: {
    onMouseleave () {
      if (this.changeDisplay) {
        this.changeDisplay = false
      }
    },
    displayChange () {
      this.changeDisplay = true
    },
    onEpisodeDisplayChange (bool) {
      this.$store
        .dispatch('episode/updateEpisodeDisplay', {
          id: this.episode.id,
          display: bool
        })
        .then(() => {
          this.display = bool
          this.changeDisplay = false
        })
        .catch(() => {
          this.changeDisplay = false
        })
    },
    setDeleteMode (event) {
      event.preventDefault()
      this.deleteMode = true
    },
    cancelDelete (event) {
      event.preventDefault()
      this.deleteMode = false
    },
    deleteEpisode (event) {
      event.preventDefault()
      this.$emit('show-delete-episode-modal', this.episode)
    }
  }
}
</script>

<style scoped>
.episode-card {
  width: 226px;
  height: 500px;
  padding: 10px;
  margin-bottom: 24px;
  transition: all 0.2s linear;
  cursor: pointer;
  float: left;
}
.episode-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19);
}
.episode-card > .title {
  margin: 0;
}
.episode-link {
  color: black;
  text-decoration: none;
  font-weight: 100;
}
img.profile {
  vertical-align: middle;
}
</style>
