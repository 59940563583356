<template>
  <div id="app">
    <Header ref="header" />
    <HeaderSp ref="headersp" />
    <router-view @select-tag="onSelectTag" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import HeaderSp from '@/components/HeaderSp.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    HeaderSp,
    Footer
  },
  methods: {
    onSelectTag: function (tag) {
      this.$refs.header.onSelectTag(tag)
      this.$refs.headersp.onSelectTag(tag)
    }
  }
}
</script>

<style></style>
