import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import DateFilter from './filters/date'
import VModal from 'vue-js-modal'
import MediumEditor from 'vuejs-medium-editor-colorpicker'
import { VueMasonryPlugin } from 'vue-masonry'
import VueAnalytics from 'vue-analytics'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'

Vue.component('medium-editor', MediumEditor)

Vue.filter('date', DateFilter)

Vue.use(VModal)
Vue.use(VueMasonryPlugin)

var datepickerOptions = {
  // dateLabelFormat: 'YYYY/MM',
  days: ['月', '火', '水', '木', '金', '土', '日'],
  daysShort: ['月', '火', '水', '木', '金', '土', '日'],
  monthNames: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月'
  ],
  colors: {
    selected: '#84c2db',
    inRange: '#d1ecf7',
    selectedText: '#000',
    text: '#8E8E8E',
    inRangeBorder: '#fff',
    disabled: '#fff',
    hoveredInRange: '#d1ecf7'
  },
  texts: {
    apply: '適用',
    cancel: 'キャンセル',
    keyboardShortcuts: 'キーボードショートカット'
  }
}

Vue.use(AirbnbStyleDatepicker, datepickerOptions)

var gaId = 'UA-162747674-1'
if (process.env.NODE_ENV === 'development') {
  gaId = 'UA-163772529-1'
}

Vue.use(VueAnalytics, {
  id: gaId,
  router
})

Vue.config.productionTip = false

const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_TOKEN', userData)
      if (!this.$store.state.loggedInUser) {
        this.$store
          .dispatch('updateLoggedInUser')
      }
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
