<template>
  <p class="profile" :class="profileColor">
    <router-link :to="{ name: 'user-show', params: { id: user.id } }">
      <img
        :src="profileImage + '?d=50x50'"
        :alt="user.full_name"
        class="obj-fit-img"
      >
      <span>{{ user.full_name }}</span>
    </router-link>
  </p>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    profileImage: function () {
      if (
        this.user.profile_image &&
        this.user.profile_image !== null &&
        this.user.profile_image !== ''
      ) {
        return this.user.profile_image
      }
      return '/img/img_profile.png'
    },
    profileColor: function () {
      if (
        this.user.profile_color &&
        this.user.profile_color !== null &&
        this.user.profile_color !== ''
      ) {
        return this.user.profile_color
      }
      return 'color01'
    }
  }
}
</script>
