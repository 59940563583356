import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  newsList: [],
  pageInfo: {}
}

export const mutations = {
  SET_NEWSLIST (state, newsList) {
    state.newsList = newsList
  },
  SET_PAGEINFO (state, pageInfo) {
    state.pageInfo = pageInfo
  },
  ADD_NEWSLIST (state, newsList) {
    state.newsList = state.newsList.concat(newsList)
  }
}

export const actions = {
  fetchNewsList ({ commit, dispatch, state }, args) {
    return GraphQLService.getNewsList(args)
      .then(response => {
        if (args.add) {
          commit('ADD_NEWSLIST', response.data.data.newsList.edges)
        } else {
          commit('SET_NEWSLIST', response.data.data.newsList.edges)
        }
        commit('SET_PAGEINFO', response.data.data.newsList.pageInfo)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching newsList: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
