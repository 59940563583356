import axios from 'axios'
import NProgress from 'nprogress'

export function authHeader () {
  const userData = JSON.parse(localStorage.getItem('user'))
  const authHeader =
    userData && userData.token ? 'Bearer ' + userData.token : null

  return authHeader
}

export function baseUrl () {
  switch (process.env.NODE_ENV) {
    case 'development':
      return { baseURL: process.env.VUE_APP_API_SERVER }
    case 'production':
      return { baseURL: '//api.timecapsules.jp' }
    default:
      return { baseURL: '' }
  }
}

export function getApiClient () {
  const apiClient = axios.create({
    ...baseUrl(),
    jar: true,
    withCredentials: false,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  })

  apiClient.interceptors.request.use(config => {
    const token = authHeader()
    if (token != null) {
      config.headers.Authorization = token
    }
    NProgress.start()
    return config
  })

  apiClient.interceptors.response.use(
    response => {
      NProgress.done()
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )

  return apiClient
}
