import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  yearAffairs: [],
  yearAffairsTotal: 0
}

export const mutations = {
  SET_YEAR_AFFAIRS (state, yearAffairs) {
    state.yearAffairs = yearAffairs
  },
  SET_YEAR_AFFAIRS_TOTAL (state, yearAffairsTotal) {
    state.yearAffairsTotal = yearAffairsTotal
  }
}

export const actions = {
  fetchYearAffairs ({ commit, dispatch, state }) {
    return GraphQLService.getYearAffairs()
      .then(response => {
        commit(
          'SET_YEAR_AFFAIRS_TOTAL',
          parseInt(response.headers['x-total-count'])
        )
        commit('SET_YEAR_AFFAIRS', response.data.data.yearAffairs)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching yearAffairs: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}

export const getters = {
  getYearAffairByYear: state => year => {
    return state.yearAffairs.find(yearAffair => yearAffair.year && yearAffair.year === year)
  }
}
