import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  blogs: [],
  hasNextPage: false,
  endCursor: null
}

export const mutations = {
  SET_BLOGS (state, blogs) {
    state.blogs = blogs
  },
  ADD_BLOGS (state, blogs) {
    state.blogs = state.blogs.concat(blogs)
  },
  SET_HAS_NEXT_PAGE (state, hasNextPage) {
    state.hasNextPage = hasNextPage
  },
  SET_END_CURSOR (state, endCursor) {
    state.endCursor = endCursor
  }
}

export const actions = {
  fetchBlogs ({ commit, dispatch, state }, args) {
    return GraphQLService.getBlogs(args)
      .then(response => {
        if (args.add) {
          commit('ADD_BLOGS', response.data.data.blogs.edges)
        } else {
          commit('SET_BLOGS', response.data.data.blogs.edges)
        }
        commit('SET_HAS_NEXT_PAGE', response.data.data.blogs.pageInfo.hasNextPage)
        commit('SET_END_CURSOR', response.data.data.blogs.pageInfo.endCursor)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching blogs: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
