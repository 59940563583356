export function timeAgoString (date) {
  const now = new Date()

  var t = date.split(/[- :]/)
  var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
  const ago = new Date(d)

  const diff = (now - ago) / 1000

  if (diff < (60)) {
    return parseInt(diff) + '秒前'
  } else if (diff < (60 * 60)) {
    return parseInt(diff / 60) + '分前'
  } else if (diff < (60 * 60 * 24)) {
    return parseInt(diff / (60 * 60)) + '時間'
  } else {
    return parseInt(diff / (60 * 60 * 24)) + '日前'
  }
}

export function commonDate (date, format) {
  var t = date.split(/[- :]/)
  var da = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
  const d = new Date(da)
  format = format.replace(/YYYY/, d.getFullYear())
  format = format.replace(/MM/, d.getMonth() + 1)
  format = format.replace(/DD/, d.getDate())
  format = format.replace(/hh/, d.getHours())
  format = format.replace(/mm/, d.getMinutes())
  format = format.replace(/ss/, d.getSeconds())

  return format
}
