<template>
  <a v-if="isBlocked(propUser.id)" @click="unblock">
    解除
  </a>
  <a v-else @click="block">
    ブロック
  </a>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    propUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      isBlocked: 'user/isBlocked'
    })
  },
  methods: {
    block () {
      this.$store
        .dispatch('user/blockUser', this.propUser.id)
        .then(() => {})
        .catch(() => {})
    },
    unblock () {
      this.$store
        .dispatch('user/unblockUser', this.propUser.id)
        .then(() => {})
        .catch(() => {})
    }
  }
}
</script>
