import { getApiClient } from '@/helpers/request-options.js'

const apiClient = getApiClient()

export default {
  postEpisode (episode) {
    return apiClient.post('/api/episode', episode)
  },
  updateEpisode (episode) {
    return apiClient.put('/api/episode/' + episode.id, episode)
  }
}
