import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  page: {}
}

export const mutations = {
  SET_PAGE (state, page) {
    state.page = page
  }
}

export const actions = {
  fetchPage ({ commit, dispatch, getters }, id) {
    return GraphQLService.getPage(id)
      .then(response => {
        commit('SET_PAGE', { node: response.data.data.page })
        return { node: response.data.data.page }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching page: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
