import * as gql from 'gql-query-builder'
import { getApiClient } from '@/helpers/request-options.js'

const apiClient = getApiClient()

export default {
  getTags () {
    const query = gql.query({
      operation: 'tags',
      fields: [
        'id',
        'name'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getUser (args) {
    const query = gql.query({
      operation: 'user',
      variables: args,
      fields: [
        'id',
        'full_name',
        'lastname',
        'firstname',
        'gender',
        'lastname_kana',
        'firstname_kana',
        'birthday',
        'birthday_display',
        'prefecture',
        'profile_image',
        'cover_image',
        'profile_color',
        'cover_color',
        'is_owner',
        'email',
        'contact_email1',
        'contact_email2',
        'contact_email3',
        'contact_name1',
        'contact_name2',
        'contact_name3',
        'profile_color',
        'cover_color',
        'mailmagazine'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getEpisodes (args) {
    const search = {
      first: args.first,
      after: args.after,
      filter: args.filter,
      page: args.page
    }

    const query = gql.query({
      operation: 'episodes',
      variables: {
        search: {
          value: search,
          type: 'EpisodeSearchInput',
          required: true
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'title',
              'header',
              'header_image',
              'time_display',
              'display',
              'allow_comments',
              'likes',
              'views',
              'has_liked',
              'date',
              'is_owner',
              {
                tags: [
                  'id',
                  'name'
                ]
              },
              {
                user: [
                  'id',
                  'full_name',
                  'profile_image',
                  'profile_color'
                ]
              }
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  getEpisode (id) {
    const query = gql.query({
      operation: 'episode',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      },
      fields: [
        'id',
        'title',
        'description',
        'description_stripped',
        'message',
        'header',
        'header_image',
        'time',
        'time_display',
        'year',
        'month',
        'day',
        'age',
        'display',
        'allow_comments',
        'likes',
        'has_liked',
        'date',
        'is_owner',
        {
          tags: [
            'id',
            'name'
          ]
        },
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  postEpisodeComment (args) {
    const input = {
      id: args.id,
      comment: args.comment
    }

    const query = gql.mutation({
      operation: 'episodeComment',
      variables: {
        input: {
          value: input,
          type: 'CommentInput',
          required: true
        }
      },
      fields: [
        'id',
        'comment',
        'date',
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getEpisodeComments (args) {
    const cursor = {
      first: args.first,
      after: args.after
    }
    const input = {
      id: args.id,
      cursor
    }

    const query = gql.query({
      operation: 'episodeComments',
      variables: {
        input: {
          value: input,
          type: 'CommentsSearchInput',
          required: false
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'comment',
              'date',
              {
                user: [
                  'id',
                  'full_name',
                  'profile_image',
                  'profile_color'
                ]
              }
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  like (args) {
    const query = gql.mutation({
      operation: 'like',
      variables: {
        input: {
          value: args,
          type: 'LikeInput',
          required: true
        }
      },
      fields: [
        'id',
        'likes'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getHistory (args) {
    const query = gql.query({
      operation: 'history',
      variables: {
        user: {
          value: args.user,
          type: 'String',
          required: false
        }
      },
      fields: [
        'id',
        'description',
        'description_stripped',
        'header',
        'header_image',
        'allow_comments',
        'date',
        'is_owner',
        'likes',
        'has_liked',
        'display',
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  postHistoryComment (args) {
    const query = gql.mutation({
      operation: 'historyComment',
      variables: {
        input: {
          value: args,
          type: 'CommentInput',
          required: true
        }
      },
      fields: [
        'id',
        'comment',
        'date',
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getHistoryComments (args) {
    const cursor = {
      first: args.first,
      after: args.after
    }
    const input = {
      id: args.id,
      cursor
    }

    const query = gql.query({
      operation: 'historyComments',
      variables: {
        input: {
          value: input,
          type: 'CommentsSearchInput',
          required: false
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'comment',
              'date',
              {
                user: [
                  'id',
                  'full_name',
                  'profile_image',
                  'profile_color'
                ]
              }
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  getTimetable (args) {
    const query = gql.query({
      operation: 'timetable',
      variables: {
        user: {
          value: args.user,
          type: 'String',
          required: false
        }
      },
      fields: [
        'id',
        {
          years: [
            'year',
            'comment',
            'image'
          ]
        },
        'allow_comments',
        'date',
        'is_owner',
        'likes',
        'has_liked',
        'display',
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  postTimetableComment (args) {
    const query = gql.mutation({
      operation: 'timetableComment',
      variables: {
        input: {
          value: args,
          type: 'CommentInput',
          required: true
        }
      },
      fields: [
        'id',
        'comment',
        'date',
        {
          user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getTimetableComments (args) {
    const cursor = {
      first: args.first,
      after: args.after
    }
    const input = {
      id: args.id,
      cursor
    }

    const query = gql.query({
      operation: 'timetableComments',
      variables: {
        input: {
          value: input,
          type: 'CommentsSearchInput',
          required: false
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'comment',
              'date',
              {
                user: [
                  'id',
                  'full_name',
                  'profile_image',
                  'profile_color'
                ]
              }
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  updateEpisodeDisplay (episode) {
    const query = gql.mutation({
      operation: 'updateEpisode',
      variables: {
        input: {
          value: episode,
          type: 'EpisodeInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteEpisode (episode) {
    const query = gql.mutation({
      operation: 'deleteEpisode',
      variables: {
        id: {
          value: episode.id,
          type: 'String',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  updateUserEmail (emailAuth) {
    const query = gql.mutation({
      operation: 'updateUserEmail',
      variables: {
        input: {
          value: emailAuth,
          type: 'EmailAuthInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  updateUserContactEmail (emailAuth) {
    const query = gql.mutation({
      operation: 'updateUserContactEmail',
      variables: {
        input: {
          value: emailAuth,
          type: 'EmailAuthInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteUser (user) {
    const query = gql.mutation({
      operation: 'deleteUser',
      variables: {
        input: {
          value: user,
          type: 'UserInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getNewsList (args) {
    const search = {
      first: args.first,
      after: args.after
    }

    const query = gql.query({
      operation: 'newsList',
      variables: {
        search: {
          value: search,
          type: 'NewsSearchInput',
          required: true
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'title',
              'description',
              'display_date'
            ]
          }]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getPage (args) {
    const query = gql.query({
      operation: 'page',
      variables: args,
      fields: [
        'id',
        'description'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  uploadFile (args) {
    var formData = new FormData()
    const query = 'mutation($input: FileUploadInput) { uploadFile(input: $input) {' +
                  ' id' +
                  ' } }'

    const fileUploadInput = {
      file: args.file
    }

    formData.append('operations', JSON.stringify({ query, variables: { fileUploadInput } }))

    formData.append('map', JSON.stringify({ 0: ['variables.fileUploadInput.file'] }))

    formData.append('0', args.file)

    formData.append('type', args.type)

    return apiClient.post('/graphql/', formData)
  },
  sendContact (contact) {
    const query = gql.mutation({
      operation: 'sendContact',
      variables: {
        input: {
          value: contact,
          type: 'ContactInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  sendMessage (message) {
    const query = gql.mutation({
      operation: 'sendMessage',
      variables: {
        input: {
          value: message,
          type: 'MessageInput',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getMessages (args) {
    const search = {
      first: args.first,
      after: args.after,
      page: args.page
    }

    const query = gql.query({
      operation: 'messages',
      variables: {
        search: {
          value: search,
          type: 'MessageSearchInput',
          required: true
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'header',
              'created',
              {
                from_user: [
                  'id',
                  'full_name',
                  'profile_image',
                  'profile_color'
                ]
              }
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  getMessage (id) {
    const query = gql.query({
      operation: 'message',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      },
      fields: [
        'id',
        'message',
        'created',
        {
          from_user: [
            'id',
            'full_name',
            'profile_image',
            'profile_color'
          ]
        }
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getBlogs (args) {
    const search = {
      first: args.first,
      after: args.after
    }

    const query = gql.query({
      operation: 'blogs',
      variables: {
        search: {
          value: search,
          type: 'BlogSearchInput',
          required: true
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'title',
              'header',
              'description',
              'image',
              'display_date',
              'name',
              'profile_image'
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteMessage (message) {
    const query = gql.mutation({
      operation: 'deleteMessage',
      variables: {
        id: {
          value: message.id,
          type: 'String',
          required: false
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getPageviews (args) {
    const search = {
      first: args.first,
      after: args.after,
      filter: args.filter
    }

    const query = gql.query({
      operation: 'pageviews',
      variables: {
        search: {
          value: search,
          type: 'PageviewSearchInput',
          required: true
        }
      },
      fields: [
        {
          pageInfo: [
            'hasNextPage',
            'hasPreviousPage',
            'startCursor',
            'endCursor'
          ]
        },
        {
          edges: [{
            node: [
              'id',
              'view_count',
              'displayed',
              'title',
              'category',
              'href'
            ]
          }]
        }]
    })
    return apiClient.post('/graphql/', query)
  },
  getCoverImages () {
    const query = gql.query({
      operation: 'coverImages',
      fields: [
        'id',
        'image'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteEpisodeComment (comment) {
    const query = gql.mutation({
      operation: 'deleteEpisodeComment',
      variables: {
        id: {
          value: comment.id,
          type: 'Int',
          required: true
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteHistoryComment (comment) {
    const query = gql.mutation({
      operation: 'deleteHistoryComment',
      variables: {
        id: {
          value: comment.id,
          type: 'Int',
          required: true
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  deleteTimetableComment (comment) {
    const query = gql.mutation({
      operation: 'deleteTimetableComment',
      variables: {
        id: {
          value: comment.id,
          type: 'Int',
          required: true
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  blockUser (id) {
    const query = gql.mutation({
      operation: 'blockUser',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  unblockUser (id) {
    const query = gql.mutation({
      operation: 'unblockUser',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      },
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getBlockList () {
    const query = gql.query({
      operation: 'blocklist',
      fields: [
        'id'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  isBlockedFrom (id) {
    const query = gql.query({
      operation: 'isBlockedFrom',
      variables: { id: id },
      fields: [
        'blocked'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getUnreadNewsCount () {
    const query = gql.query({
      operation: 'unreadNewsCount',
      fields: [
        'count'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  updateLastNewsViewed () {
    const query = gql.mutation({
      operation: 'updateLastNewsViewed',
      fields: [
        'count'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getUnreadMessageCount () {
    const query = gql.query({
      operation: 'unreadMessageCount',
      fields: [
        'count'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  messageViewed (id) {
    const query = gql.mutation({
      operation: 'messageViewed',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      },
      fields: [
        'count'
      ]
    })
    return apiClient.post('/graphql/', query)
  },
  getYearAffairs () {
    const query = gql.query({
      operation: 'yearAffairs',
      fields: [
        'year',
        'jyear',
        'affair'
      ]
    })
    return apiClient.post('/graphql/', query)
  }
}
