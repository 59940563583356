<template>
  <div class="hashArea">
    <ul
      ref="tagWrapUl"
      @mousedown="touchstart($event)"
      @touchstart="touchstart($event)"
      @mousemove="touchmove($event)"
      @touchmove="touchmove($event)"
      @mouseup="touchend($event)"
      @mouseleave="touchend($event)"
      @touchend="touchend($event)"
    >
      <li
        v-for="t in tag.tags"
        :key="t.id"
        :ref="'tag' + t.id"
        :tag="t"
        @click="fetchEpisodes($event, t)"
      >
        <a>#{{ t.name }}</a>
      </li>
    </ul>
    <a :style="hashBtnPrev" class="hashBtnPrev" @click="slidePrev" />
    <a :style="hashBtnNext" class="hashBtnNext" @click="slideNext" />
  </div>
</template>

<script>
import store from '@/store/index'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      master: 0,
      num: 0,
      is_mousedown: false,
      pos: 0,
      windowWidth: window.innerWidth,
      width: 0
    }
  },
  computed: {
    ...mapState(['tag', 'episode']),
    hashBtnPrev: function () {
      if (this.pos === 0) {
        return { display: 'none' }
      } else {
        return { display: 'flex' }
      }
    },
    hashBtnNext: function () {
      if (this.width === 0) {
        return { display: 'flex' }
      } else if (this.pos <= (this.width - this.windowWidth) * -1) {
        return { display: 'none' }
      } else {
        return { display: 'flex' }
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    store.dispatch('tag/fetchTags', {}).then(() => {
      this.handleResize()
    })
  },
  methods: {
    fetchEpisodes (event, tag) {
      event.preventDefault()
      store.dispatch('episode/setTag', { tag }).then(() => {
        this.$emit('select-tag', tag)
        const args = { first: 12, add: false }
        store.dispatch('episode/fetchEpisodes', args)
      })
    },
    handleResize: function () {
      this.windowWidth = window.innerWidth
      this.width = 0
      for (var i = 0, len = this.tag.tags.length; i < len; i++) {
        if (this.$refs['tag' + this.tag.tags[i].id] !== undefined) {
          this.width += this.$refs['tag' + this.tag.tags[i].id][0].offsetWidth
        }
      }
    },
    slideNext: function () {
      this.pos -= this.windowWidth
      this.$refs.tagWrapUl.style.transform = 'translateX(' + this.pos + 'px)'
    },
    slidePrev: function () {
      this.pos += this.windowWidth
      if (this.pos > 0) {
        this.pos = 0
      }
      this.$refs.tagWrapUl.style.transform = 'translateX(' + this.pos + 'px)'
    },
    touchstart: function (event) {
      this.is_mousedown = true
      this.num =
        event.pageX !== undefined ? event.pageX : event.changedTouches[0].pageX
    },
    touchmove: function (event) {
      if (this.pos === 0 && this.pos <= (this.width - this.windowWidth) * -1) {
        return
      }

      if (this.is_mousedown) {
        const epos =
          event.pageX !== undefined
            ? event.pageX
            : event.changedTouches[0].pageX
        this.pos =
          epos - this.num + this.master < 0 ? epos - this.num + this.master : 0
        this.pos =
          this.pos <= this.windowWidth - this.width
            ? this.windowWidth - this.width
            : this.pos
        this.$refs.tagWrapUl.style.transform = 'translateX(' + this.pos + 'px)'
      }
    },
    touchend: function (event) {
      this.is_mousedown = false
      this.master = this.pos
    }
  }
}
</script>
<style scoped>
ul {
  transition: 0.6s ease-out;
}
</style>
