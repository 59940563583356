<template>
  <li :class="reply">
    <p class="profile" :class="comment.user.profile_color">
      <a><BaseUserImg :user="comment.user" /><span>{{
        comment.user.full_name
      }}</span></a><span class="written">{{ ago(comment.date) }}</span>
    </p>
    <p class="text" style="white-space: pre-line;">
      {{ comment.comment }}
    </p>
    <div v-if="canEdit" class="editBox">
      <ul>
        <li class="delete">
          <a @click="deleteComment($event)">削除</a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import { timeAgoString } from '@/helpers/date-format.js'

export default {
  name: 'CommentCard',
  props: {
    comment: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    owner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    canEdit: function () {
      return (
        this.$store.getters.isOwner(this.comment.user.id) ||
        this.$store.getters.isOwner(this.owner.id)
      )
    },
    reply: function () {
      if (this.$store.getters.isOwner(this.comment.user.id)) {
        return 'reply'
      }
      return null
    }
  },
  created: function () {},
  methods: {
    ago (date) {
      return timeAgoString(date)
    },
    deleteComment (event) {
      event.preventDefault()
      this.$store
        .dispatch(this.type + 'Comment/delete', this.comment)
        .then(() => {})
        .catch(() => {})

      return true
    }
  }
}
</script>

<style></style>
