import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import store from '@/store/index'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const title = 'TIME CAPSULE'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'TIME CAPSULE あなたの物語が歴史の1ページになる'
      next()
    }
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = '会員登録 | ' + title
      next()
    }
  },
  {
    path: '/create/:email/:key',
    name: 'create',
    component: () =>
      import(/* webpackChunkName: "create" */ '../views/Create.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('check', {
          email: routeTo.params.email,
          key: routeTo.params.key
        })
        .then(user => {
          document.title = '会員情報の入力 | ' + title
          routeTo.params.user = user
          next()
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'userRegistration' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () =>
      import(/* webpackChunkName: "confirm" */ '../views/Confirm.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = '会員情報の確認 | ' + title
      next()
    }
  },
  {
    path: '/complete',
    name: 'complete',
    component: () =>
      import(/* webpackChunkName: "complete" */ '../views/Complete.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = '会員登録の完了 | ' + title
      next()
    }
  },
  {
    path: '/user/email/update/:email/:key',
    name: 'user-email-update',
    component: () =>
      import(/* webpackChunkName: "update-email-update" */ '../views/UserEmailUpdate.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/updateUserEmail', {
          email: routeTo.params.email,
          auth_key: routeTo.params.key
        })
        .then(response => {
          if (response.data.errors) {
            next({ name: '404', params: { resource: 'email' } })
          } else {
            document.title = 'メーアドレスの更新完了 | ' + title
            next()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'email' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/user/contact_email/update/:email/:key',
    name: 'user-contact_email-update',
    component: () =>
      import(/* webpackChunkName: "update-contact_email-update" */ '../views/UserContactEmailUpdate.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/updateUserContactEmail', {
          email: routeTo.params.email,
          auth_key: routeTo.params.key
        })
        .then(response => {
          if (response.data.errors) {
            next({ name: '404', params: { resource: 'email' } })
          } else {
            document.title = 'アカウント継続連絡先メーアドレスの更新完了 | ' + title
            next()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'email' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'ログイン | ' + title
      next()
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter (routeTo, routeFrom, next) {
      const body = document.getElementsByTagName('body')[0]
      body.classList.remove('statusLogin')

      store
        .dispatch('logout')
        .then(() => {
          next({ name: 'home' })
        })
    }
  },
  {
    path: '/reminder',
    name: 'reminder',
    component: () =>
      import(/* webpackChunkName: "reminder" */ '../views/Reminder.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'パスワード再設定 | ' + title
      next()
    }
  },
  {
    path: '/reset/:id',
    name: 'reset-password',
    component: () =>
      import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      document.title = '新しいパスワードを設定 | ' + title
      next()
    }
  },
  {
    path: '/episode/create',
    name: 'episode-create',
    component: () =>
      import(/* webpackChunkName: "episode-create" */ '../views/EpisodeCreate.vue'),
    meta: { requiresAuth: true },
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            document.title = '新しいエピソードの入力 | ' + title
            next()
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/episode/:id',
    name: 'episode-show',
    component: () =>
      import(/* webpackChunkName: "episode-show" */ '../views/EpisodeShow.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('episode/fetchEpisode', routeTo.params.id)
        .then(episode => {
          if (!episode.node) {
            next({ name: '404', params: { resource: 'episode' } })
          } else {
            document.title = episode.node.title + ' | ' + title
            routeTo.params.e = episode.node
            next()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'episode' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/episode/edit/:id',
    name: 'episode-edit',
    component: () =>
      import(/* webpackChunkName: "episode-edit" */ '../views/EpisodeEdit.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('episode/fetchEpisode', routeTo.params.id)
        .then(episode => {
          if (!episode.node) {
            next({ name: 'logout' })
          }
          const tags = episode.node.tags.map(t => {
            return { text: t.name }
          })
          document.title = 'エピソードの編集 | ' + title
          episode.node.tags = tags
          routeTo.params.e = episode.node
          next()
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'episode' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/user/:id',
    name: 'user-show',
    component: () =>
      import(/* webpackChunkName: "user-show" */ '../views/UserShow.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', { id: routeTo.params.id })
        .then(user => {
          if (!user) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            document.title = user.full_name + 'さんのプロフィール | ' + title
            routeTo.params.u = user
            next()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/user/:id/history',
    name: 'user-history',
    component: () =>
      import(/* webpackChunkName: "user-history" */ '../views/UserHistory.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', { id: routeTo.params.id })
        .then(user => {
          if (!user) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            routeTo.params.u = user
            store
              .dispatch('history/fetchHistory', { user: routeTo.params.id })
              .then(history => {
                document.title = user.full_name + 'さんの自分史 | ' + title
                if (history && history.node) {
                  routeTo.params.history = history.node
                } else {
                  routeTo.params.history = {}
                }
                next()
              })
              .catch(error => {
                if (error.response && error.response.status === 404) {
                  next({ name: '404', params: { resource: 'history' } })
                } else {
                  next({ name: 'network-issue' })
                }
              })
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/user/:id/timetable',
    name: 'user-timetable',
    component: () =>
      import(/* webpackChunkName: "user-timetable" */ '../views/UserTimetable.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', { id: routeTo.params.id })
        .then(user => {
          if (!user) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            routeTo.params.u = user
            store
              .dispatch('timetable/fetchTimetable', { user: routeTo.params.id })
              .then(timetable => {
                document.title = user.full_name + 'さんの年表 | ' + title
                if (timetable && timetable.node) {
                  routeTo.params.timetable = timetable.node
                } else {
                  routeTo.params.timetable = {}
                }
                next()
              })
              .catch(error => {
                if (error.response && error.response.status === 404) {
                  next({ name: '404', params: { resource: 'timetable' } })
                } else {
                  next({ name: 'network-issue' })
                }
              })
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'user' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/history/edit',
    name: 'history-edit',
    component: () =>
      import(/* webpackChunkName: "history-edit" */ '../views/HistoryEdit.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('history/fetchHistory', {})
        .then(history => {
          document.title = '自分史の編集 | ' + title
          if (history && history.node) {
            routeTo.params.history = history.node
          } else {
            routeTo.params.history = { allow_comments: true }
          }
          next()
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'history' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/timetable/edit',
    name: 'timetable-edit',
    component: () =>
      import(/* webpackChunkName: "timetable-edit" */ '../views/TimetableEdit.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('timetable/fetchTimetable', {})
        .then(timetable => {
          document.title = '年表の編集 | ' + title
          if (timetable && timetable.node) {
            routeTo.params.timetable = timetable.node
          } else {
            routeTo.params.timetable = { allow_comments: true }
          }
          next()
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            next({ name: '404', params: { resource: 'timetable' } })
          } else {
            next({ name: 'network-issue' })
          }
        })
    }
  },
  {
    path: '/user/inbox/list',
    name: 'user-inbox',
    component: () =>
      import(/* webpackChunkName: "user-inbox" */ '../views/UserInbox.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            document.title = 'メッセージ一覧 | ' + title
            routeTo.params.u = user
            next()
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/user/inbox/:id',
    name: 'message-show',
    component: () =>
      import(/* webpackChunkName: "message-show" */ '../views/UserMessageShow.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            routeTo.params.u = user
            store
              .dispatch('user/fetchMessage', routeTo.params.id)
              .then(message => {
                if (!message.node) {
                  next({ name: '404', params: { resource: 'message' } })
                } else {
                  document.title = 'メッセージ詳細 | ' + title
                  routeTo.params.m = message.node
                  next()
                }
              })
              .catch(error => {
                if (error.response && error.response.status === 404) {
                  next({ name: '404', params: { resource: 'message' } })
                } else {
                  next({ name: 'network-issue' })
                }
              })
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/user/pageview/list',
    name: 'user-pageview',
    component: () =>
      import(/* webpackChunkName: "user-pageview" */ '../views/UserPageview.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            document.title = 'ページビュー | ' + title
            routeTo.params.u = user
            next()
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/user/edit/profile',
    name: 'user-edit',
    component: () =>
      import(/* webpackChunkName: "user-edit" */ '../views/UserEdit.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            document.title = 'プロフィールの編集 | ' + title
            routeTo.params.u = user
            next()
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/user/delete/complete',
    name: 'user-delete-complete',
    component: () =>
      import(/* webpackChunkName: "user-delete-complete" */ '../views/UserDeleteComplete.vue')
  },
  {
    path: '/user/delete/confirm',
    name: 'user-delete',
    component: () =>
      import(/* webpackChunkName: "user-delete" */ '../views/UserDelete.vue'),
    meta: { requiresAuth: true },
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      store
        .dispatch('user/fetchUser', {})
        .then(user => {
          if (!user) {
            next({ name: 'logout' })
          } else {
            document.title = '解約する | ' + title
            routeTo.params.u = user
            next()
          }
        })
        .catch(() => {
          next({ name: 'network-issue' })
        })
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/News.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'お知らせ | ' + title
      next()
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () =>
      import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = '運営ブログ | ' + title
      next()
    }

  },
  {
    path: '/html/:page_type',
    name: 'page',
    component: () =>
      import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      var pageId = null
      switch (routeTo.params.page_type) {
        case 'about':
          document.title = 'タイムカプセルについて | ' + title
          pageId = 1
          break
        case 'howto':
          document.title = '使い方 | ' + title
          pageId = 2
          break
        case 'company':
          document.title = '運営会社 | ' + title
          pageId = 3
          break
        case 'privacy':
          document.title = 'プライバシーポリシー | ' + title
          pageId = 4
          break
        case 'terms':
          document.title = '利用規約 | ' + title
          pageId = 5
          break
        default:
      }

      if (pageId === null) {
        next({ name: '404', params: { resource: 'ページ' } })
      } else {
        store
          .dispatch('page/fetchPage', { id: pageId })
          .then(page => {
            if (!page.node) {
              next({ name: '404', params: { resource: 'ページ' } })
            } else {
              routeTo.params.p = page.node
              next()
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              next({ name: '404', params: { resource: 'ページ' } })
            } else {
              next({ name: 'network-issue' })
            }
          })
      }
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'お問い合わせ | ' + title
      next()
    }
  },
  {
    path: '/404',
    name: '404',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    props: true,
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'ページが見つかりません | ' + title
      next()
    }
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: () =>
      import(/* webpackChunkName: "network-issue" */ '../views/NetworkIssue.vue'),
    beforeEnter (routeTo, routeFrom, next) {
      document.title = 'サーバーエラー | ' + title
      next()
    }
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((routeTo, routeFrom, next) => {
  const loggedIn = localStorage.getItem('user')
  if (routeTo.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  } else {
    NProgress.start()
    if (loggedIn) {
      const now = +new Date()
      const interval = 3 * 60 * 1000
      if (store.getters.getLastStatusUpdate === null || store.getters.getLastStatusUpdate + interval < now) {
        store.dispatch('getUnreadNewsCount').then(() => {
          store
            .dispatch('updateLastStatusUpdate')
        })
      }
    }
    next()
  }
})

router.afterEach(() => {
  store
    .dispatch('updateLastActive')
  NProgress.done()

  const loggedIn = localStorage.getItem('user')
  const body = document.getElementsByTagName('body')[0]
  if (loggedIn) {
    body.classList.add('statusLogin')
  } else {
    body.classList.remove('statusLogin')
  }
})

export default router
