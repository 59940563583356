import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  comments: [],
  pageInfo: {}
}

export const mutations = {
  SET_COMMENTS (state, comments) {
    state.comments = comments
  },
  ADD_COMMENTS (state, comments) {
    state.comments = state.comments.concat(comments)
  },
  SET_PAGEINFO (state, pageInfo) {
    state.pageInfo = pageInfo
  },
  ADD_COMMENT (state, comment) {
    state.comments.unshift(comment)
  },
  DELETE_COMMENT (state, commentToRemove) {
    state.comments = state.comments.filter(
      comment => comment.node.id !== commentToRemove.id
    )
  }
}

export const actions = {
  postComment ({ commit, dispatch }, args) {
    return GraphQLService.postHistoryComment(args)
      .then(res => {
        commit('ADD_COMMENT', { node: res.data.data.historyComment })
        return res
      })
      .catch(error => {
        throw error
      })
  },
  fetchComments ({ commit, dispatch, state }, args) {
    return GraphQLService.getHistoryComments(args)
      .then(response => {
        if (args.add) {
          commit('ADD_COMMENTS', response.data.data.historyComments.edges)
        } else {
          commit('SET_COMMENTS', response.data.data.historyComments.edges)
        }
        commit('SET_PAGEINFO', response.data.data.historyComments.pageInfo)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching comments: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  delete ({ commit, dispatch }, comment) {
    return GraphQLService.deleteHistoryComment(comment)
      .then(res => {
        commit('DELETE_COMMENT', comment)
        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem deleting your comment: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
