<template>
  <div>
    <TagList @select-tag="onSelectTag" />

    <div :id="pageClassName" class="mainContents">
      <div v-if="showBanner" class="titleArea">
        <section class="secTitle">
          <p class="catch">
            60代、次の世代へ<br class="sp">手わたすコトバがある。
          </p>
          <h1>
            <img src="img/logo_l.png" class="pc" alt="TIME CAPSULE"><img
              src="img/logo_l.png"
              class="tab"
              alt="TIME CAPSULE"
            ><img src="img/logo_l_sp.png" class="sp" alt="TIME CAPSULE">
          </h1>
        </section>
        <section class="secRead">
          <p class="read">
            ようこそ、タイムカプセルへ。<br>
            ひとり、ひとり、生きかた、考えかた、<br class="tab"><br
              class="sp"
            >大切にしてきたものがある。<br>
            誰かに伝えたいこと、<br class="sp">知ってほしいことがある。<br>
            それを残してみませんか？
          </p>
        </section>
        <nav>
          <ul>
            <li>
              <router-link :to="{ name: 'register' }">
                会員登録はこちら(無料)
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'page', params: { page_type: 'about' } }"
              >
                タイムカプセルについて
              </router-link>
            </li>
          </ul>
        </nav>
        <!-- /.titleArea -->
      </div>

      <div id="grid" class="gridArea">
        <h2 v-if="isFilteredSearch">
          "{{ getFilterString }}"での検索結果
        </h2>
        <div
          v-masonry="containerId"
          class="grid"
          transition-duration="0.3s"
          item-selector=".item"
        >
          <EpisodeCard
            v-for="episode in episode.episodes"
            :key="episode.node.id"
            :episode="episode.node"
            :edit-mode="false"
            @select-tag="onSelectTag"
          />
        </div>
        <!-- /#grid -->
      </div>

      <div class="buttonArea">
        <p>
          <button
            v-if="episode.hasNextPage"
            type="submit"
            class="button"
            @click="getMoreEpisodes($event)"
          >
            もっと見る
          </button>
        </p>
        <!-- /.buttonArea -->
      </div>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import EpisodeCard from '@/components/EpisodeCard.vue'
import { mapState, mapGetters } from 'vuex'
import store from '@/store/index'
import TagList from '@/components/TagList.vue'

function getPageEpisodes (routeTo, next) {
  const args = { first: 12, add: false, page: 'home' }
  store
    .dispatch('episode/fetchEpisodes', args)
    .then(() => {
      next()
    })
    .catch(() => {
      next({ name: 'network-issue' })
    })
}

export default {
  components: {
    EpisodeCard,
    TagList
  },
  data () {
    return {
      episodes: [],
      tag: null,
      containerId: ''
    }
  },
  beforeRouteEnter (routeTo, routeFrom, next) {
    getPageEpisodes(routeTo, next)
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    getPageEpisodes(routeTo, next)
  },
  computed: {
    ...authComputed,
    ...mapState(['episode', 'user']),
    ...mapGetters({
      isFilteredSearch: 'episode/isFilteredSearch',
      getFilterString: 'episode/getFilterString'
    }),
    pageClassName: function () {
      if (this.isFilteredSearch) {
        return 'pageResult'
      }
      return 'pageTop'
    },
    showBanner: function () {
      if (this.loggedIn) {
        return false
      }
      if (this.isFilteredSearch) {
        return false
      }
      return true
    }
  },
  mounted () {
    const body = document.getElementsByTagName('body')[0]
    if (this.$store.getters.loggedIn) {
      body.classList.add('statusLogin')
    } else {
      body.classList.remove('statusLogin')
    }
  },
  methods: {
    getMoreEpisodes (event) {
      event.preventDefault()
      const args = {
        first: 12,
        after: this.episode.endCursor,
        add: true,
        page: 'home'
      }
      store.dispatch('episode/fetchEpisodes', args)
    },
    onSelectTag (tag) {
      this.$emit('select-tag', tag)
    }
  }
}
</script>

<style scoped></style>
