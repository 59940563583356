import HistoryService from '@/services/HistoryService.js'
import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  history: {}
}

export const mutations = {
  ADD_HISTORY (state, history) {
    state.histories.push(history)
  },
  SET_HISTORY (state, history) {
    state.history = history
  }
}

export const actions = {
  updateHistory ({ commit, dispatch }, history) {
    return HistoryService.updateHistory(history)
      .then(res => {
        commit('SET_HISTORY', history)
        const notification = {
          type: 'success',
          message: 'Your history has been updated!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating your history: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchHistory ({ commit, dispatch, getters }, args) {
    return GraphQLService.getHistory(args)
      .then(response => {
        commit('SET_HISTORY', { node: response.data.data.history })
        return { node: response.data.data.history }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching history: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  like ({ commit, dispatch, getters }, args) {
    return GraphQLService.like(args)
      .then(response => {
        return response.data.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem liking episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
