<template>
  <img :src="profileImage" :alt="user.full_name" class="obj-fit-img">
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 50
    }
  },
  computed: {
    profileImage: function () {
      const size = this.size + 'x' + this.size
      if (
        this.user.profile_image &&
        this.user.profile_image !== null &&
        this.user.profile_image !== ''
      ) {
        return this.user.profile_image + '?d=' + size
      }
      return '/img/img_profile.png'
    }
  }
}
</script>
