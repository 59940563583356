import TimetableService from '@/services/TimetableService.js'
import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  timetable: {}
}

export const mutations = {
  ADD_TIMETABLE (state, timetable) {
    state.histories.push(timetable)
  },
  SET_TIMETABLE (state, timetable) {
    state.timetable = timetable
  }
}

export const actions = {
  updateTimetable ({ commit, dispatch }, timetable) {
    return TimetableService.updateTimetable(timetable)
      .then(res => {
        commit('SET_TIMETABLE', timetable)
        const notification = {
          type: 'success',
          message: 'Your timetable has been updated!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating your timetable: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchTimetable ({ commit, dispatch, getters }, args) {
    return GraphQLService.getTimetable(args)
      .then(response => {
        commit('SET_TIMETABLE', { node: response.data.data.timetable })
        return { node: response.data.data.timetable }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching timetable: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  like ({ commit, dispatch, getters }, args) {
    return GraphQLService.like(args)
      .then(response => {
        return response.data.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem liking episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
