import GraphQLService from '@/services/GraphQLService.js'
import UserService from '@/services/UserService.js'

export const namespaced = true

export const state = {
  user: null,
  message: {},
  messages: [],
  hasNextPage: false,
  endCursor: null,
  blocklist: []
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_MESSAGE (state, message) {
    state.message = message
  },
  SET_MESSAGES (state, messages) {
    state.messages = messages
  },
  ADD_MESSAGES (state, messages) {
    state.messages = state.messages.concat(messages)
  },
  DELETE_MESSAGE (state, messageToRemove) {
    state.messages = state.messages.filter(
      message => message.node.id !== messageToRemove.id
    )
  },
  SET_HAS_NEXT_PAGE (state, hasNextPage) {
    state.hasNextPage = hasNextPage
  },
  SET_END_CURSOR (state, endCursor) {
    state.endCursor = endCursor
  },
  SET_BLOCKLIST (state, blocklist) {
    state.blocklist = blocklist
  },
  ADD_BLOCKLIST (state, blocklist) {
    state.blocklist.push(blocklist)
  },
  DELETE_BLOCKLIST (state, blockListToRemove) {
    state.blocklist = state.blocklist.filter(
      blocklist => blocklist.id !== blockListToRemove.id
    )
  }
}

export const actions = {
  fetchUser ({ commit, dispatch }, args) {
    return GraphQLService.getUser(args)
      .then(response => {
        commit('SET_USER', response.data.data.user)
        return response.data.data.user
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching user: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  updateUser ({ commit, dispatch }, user) {
    return UserService.updateUser(user)
      .then(res => {
        commit('SET_USER', user)
        const notification = {
          type: 'success',
          message: 'Your user has been updated!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating your user: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  deleteUser ({ commit, dispatch }, user) {
    return GraphQLService.deleteUser(user)
      .then(res => {
        const notification = {
          type: 'success',
          message: 'Your user has been deleted!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem deleting your user: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  uploadImage ({ commit, dispatch }, file) {
    return UserService.uploadImage(file)
      .then(res => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  updateUserEmail ({ commit, dispatch }, args) {
    return GraphQLService.updateUserEmail(args)
  },
  updateUserContactEmail ({ commit, dispatch }, args) {
    return GraphQLService.updateUserContactEmail(args)
  },
  sendMessage ({ commit, dispatch }, args) {
    return GraphQLService.sendMessage(args)
  },
  fetchMessage ({ commit, dispatch, getters }, id) {
    return GraphQLService.getMessage(id)
      .then(response => {
        commit('SET_MESSAGE', { node: response.data.data.message })
        return { node: response.data.data.message }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching message: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchMessages ({ commit, dispatch, state }, args) {
    return GraphQLService.getMessages(args)
      .then(response => {
        if (args.add) {
          commit('ADD_MESSAGES', response.data.data.messages.edges)
        } else {
          commit('SET_MESSAGES', response.data.data.messages.edges)
        }
        commit('SET_HAS_NEXT_PAGE', response.data.data.messages.pageInfo.hasNextPage)
        commit('SET_END_CURSOR', response.data.data.messages.pageInfo.endCursor)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching messages: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  deleteMessage ({ commit, dispatch }, message) {
    return GraphQLService.deleteMessage(message)
      .then(res => {
        commit('DELETE_MESSAGE', message)
        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem deleting your message: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  blockUser ({ commit, dispatch }, id) {
    return GraphQLService.blockUser(id)
      .then(response => {
        commit('ADD_BLOCKLIST', response.data.data.blockUser)
        return response.data.data.blockUser
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem blocking user: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  unblockUser ({ commit, dispatch }, id) {
    return GraphQLService.unblockUser(id)
      .then(response => {
        commit('DELETE_BLOCKLIST', response.data.data.unblockUser)
        return response.data.data.unblockUser
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem unblocking user: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchBlockList ({ commit, dispatch }) {
    return GraphQLService.getBlockList()
      .then(response => {
        commit('SET_BLOCKLIST', response.data.data.blocklist)
        return response.data.data.blocklist
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching blocklist: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  isBlockedFrom ({ commit, dispatch }, id) {
    return GraphQLService.isBlockedFrom(id)
      .then(response => {
        return response.data.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching isBlockedFrom: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  messageViewed ({ commit, dispatch }, id) {
    return GraphQLService.messageViewed(id)
      .then(response => {
        return response.data.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating messageViewed: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}

export const getters = {
  isBlocked: state => id => {
    return state.blocklist.find(b => b.id === id)
  }
}
