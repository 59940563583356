import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const actions = {
  uploadAttachment ({ commit, dispatch }, file) {
    return GraphQLService.uploadFile({ file: file, type: 'attachment' })
      .then(res => {
        return res
      })
      .catch(error => {
        throw error
      })
  },
  sendContact ({ commit, dispatch }, contact) {
    return GraphQLService.sendContact(contact)
      .then(res => {
        return res
      })
      .catch(error => {
        throw error
      })
  }
}
