import EpisodeService from '@/services/EpisodeService.js'
import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  episodes: [],
  episode: {},
  hasNextPage: false,
  endCursor: null,
  filter: {
    contains: [],
    user: null,
    tags: []
  },
  searchBox: null
}

export const mutations = {
  ADD_EPISODE (state, episode) {
    state.episodes.push(episode)
  },
  DELETE_EPISODE (state, episodeToRemove) {
    state.episodes = state.episodes.filter(
      episode => episode.node.id !== episodeToRemove.id
    )
  },
  SET_EPISODES (state, episodes) {
    state.episodes = episodes
  },
  ADD_EPISODES (state, episodes) {
    state.episodes = state.episodes.concat(episodes)
  },
  SET_EPISODE (state, episode) {
    state.episode = episode
  },
  SET_HAS_NEXT_PAGE (state, hasNextPage) {
    state.hasNextPage = hasNextPage
  },
  SET_END_CURSOR (state, endCursor) {
    state.endCursor = endCursor
  },
  SET_TAG (state, tag) {
    state.filter.contains.push(tag.name)
  },
  SET_USER (state, user) {
    state.filter.user = user
  },
  SET_SEARCH (state, search) {
    state.filter.contains = search
  },
  SET_SEARCH_BOX (state, searchBox) {
    state.searchBox = searchBox
  }
}

export const actions = {
  createEpisode ({ commit, dispatch }, episode) {
    return EpisodeService.postEpisode(episode)
      .then(res => {
        commit('ADD_EPISODE', episode)
        const notification = {
          type: 'success',
          message: 'Your episode has been created!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem creating your episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  updateEpisode ({ commit, dispatch }, episode) {
    return EpisodeService.updateEpisode(episode)
      .then(res => {
        commit('SET_EPISODE', episode)
        const notification = {
          type: 'success',
          message: 'Your episode has been updated!'
        }
        dispatch('notification/add', notification, { root: true })

        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating your episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  updateEpisodeDisplay ({ commit, dispatch }, episode) {
    return GraphQLService.updateEpisodeDisplay(episode)
      .then(res => {
        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem updating your episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  deleteEpisode ({ commit, dispatch }, episode) {
    return GraphQLService.deleteEpisode(episode)
      .then(res => {
        commit('DELETE_EPISODE', episode)
        return res
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem deleting your episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchEpisodes ({ commit, dispatch, state }, args) {
    var filter = {}
    if (state.filter.contains.length > 0 && state.filter.contains[0] !== '') {
      filter.contains = state.filter.contains
    }
    if (args.tags && args.tags.length > 0) {
      filter.tags = args.tags.map(a => a.name)
    }
    if (args.not) {
      filter.not = args.not
    }
    if (state.filter.user) {
      filter.user = state.filter.user
    }
    args.filter = filter

    return GraphQLService.getEpisodes(args)
      .then(response => {
        if (args.add) {
          commit('ADD_EPISODES', response.data.data.episodes.edges)
        } else {
          commit('SET_EPISODES', response.data.data.episodes.edges)
        }
        commit('SET_HAS_NEXT_PAGE', response.data.data.episodes.pageInfo.hasNextPage)
        commit('SET_END_CURSOR', response.data.data.episodes.pageInfo.endCursor)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching episodes: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  setTag ({ commit, dispatch, state }, { tag }) {
    commit('SET_SEARCH', [])
    commit('SET_TAG', tag)
    commit('SET_SEARCH_BOX', tag)
  },
  setUser ({ commit, dispatch, state }, user) {
    commit('SET_USER', user)
  },
  setSearch ({ commit, dispatch, state }, { search }) {
    commit('SET_SEARCH', search)
  },
  fetchEpisode ({ commit, dispatch, getters }, id) {
    return GraphQLService.getEpisode(id)
      .then(response => {
        commit('SET_EPISODE', { node: response.data.data.episode })
        return { node: response.data.data.episode }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  like ({ commit, dispatch, getters }, args) {
    return GraphQLService.like(args)
      .then(response => {
        return response.data.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem liking episode: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  clearFilter ({ commit }) {
    commit('SET_SEARCH', [])
    commit('SET_SEARCH_BOX', null)
  }
}
export const getters = {
  getEpisodeById: state => id => {
    return state.episodes.find(episode => episode.node && episode.node.id === id)
  },
  isFilteredSearch: state => {
    return (state.filter.contains.length > 0 && state.filter.contains[0] !== '')
  },
  getFilterString: state => {
    var filters = []
    if (state.filter.contains.length > 0 && state.filter.contains[0] !== '') {
      filters = state.filter.contains.concat()
    }
    return filters.join(' ').trim()
  },
  getSearchBox: state => {
    return state.searchBox
  }
}
