<template>
  <!-- 共通ヘッダ SP -->
  <header class="sp">
    <div class="inner">
      <div>
        <h1>
          <a @click="closeMenuAndTransition('home')">
            <img
              src="/img/logo.png"
              alt="TIME CAPSULE あなたの物語が歴史の1ページになる"
            >
          </a>
        </h1>
        <p class="disp">
          <a :class="close" @click="toggleMenu"><span /><span /><span /></a>
        </p>
        <div
          v-if="getLoggedInUser && getLoggedInUser !== null"
          class="profileWrap"
        >
          <p class="profile">
            <router-link
              :to="{ name: 'user-show', params: { id: getLoggedInUser.id } }"
            >
              <BaseUserImg :user="getLoggedInUser" />
            </router-link>
          </p>
          <p class="edit">
            <router-link :to="{ name: 'episode-create' }">
              記事を書く
            </router-link>
          </p>
        </div>
        <div id="search-app" class="searchBox">
          <input
            v-if="hasSearch"
            type="reset"
            value="×"
            @click="onClearSearch"
          >
          <input
            v-model="searchBox"
            type="text"
            placeholder="エピソードを検索"
            @blur="onBlur"
          >
          <button>
            <img src="/img/ic_search.png" alt="検索">
          </button>
        </div>
      </div>
    </div>
    <transition name="slide">
      <nav v-if="show">
        <ul class="about">
          <li>
            <a @click="closeMenuAndTransition('about')">
              タイムカプセルについて
            </a>
          </li>
          <li>
            <a @click="closeMenuAndTransition('howto')">
              使い方
            </a>
          </li>
        </ul>
        <ul class="user">
          <li>
            <a @click="closeMenuAndTransition('news')">
              お知らせ
            </a>
          </li>
          <li>
            <a v-if="!loggedIn" @click="closeMenuAndTransition('login')">
              ログイン
            </a>
            <a v-else @click="closeMenuAndTransition('logout')">
              ログアウト
            </a>
          </li>
          <li v-if="!loggedIn">
            <a @click="closeMenuAndTransition('register')">
              会員登録
            </a>
          </li>
          <li v-else>
            <a
              v-if="getLoggedInUser"
              @click="closeMenuAndTransition('user-show')"
            >
              マイページ
            </a>
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import store from '@/store/index'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  data () {
    return {
      searchBox: null,
      doNotRefetch: false,
      show: false,
      close: null
    }
  },
  computed: {
    ...authComputed,
    ...mapState(['episode']),
    ...mapGetters({
      getSearchBox: 'episode/getSearchBox',
      getUnreadNewsCount: 'getUnreadNewsCount'
    }),
    hasSearch () {
      if (this.getSearchBox) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('episode', ['SET_SEARCH_BOX']),
    logout (event) {
      event.preventDefault()
      this.$router.push({ name: 'logout' }).catch(() => {})
    },
    onBlur: function () {
      var val = this.searchBox.replace(/　/g, " "); // eslint-disable-line
      this.SET_SEARCH_BOX(val)
      const search = val.split(' ')
      store.dispatch('episode/setSearch', { search }).then(() => {
        const args = { first: 12, add: false }
        store.dispatch('episode/fetchEpisodes', args)
      })
    },
    onClearSearch: function () {
      this.searchBox = ''
      const search = []
      store.dispatch('episode/setSearch', { search }).then(() => {
        const args = { first: 12, add: false }
        store.dispatch('episode/fetchEpisodes', args)
      })
    },
    onSelectTag: function (tag) {
      this.doNotRefetch = true
      this.searchBox = tag.name
    },
    toggleMenu: function () {
      if (this.show) {
        this.close = null
        this.show = false
      } else {
        this.close = 'close'
        this.show = true
      }
    },
    closeMenuAndTransition (page) {
      this.show = false
      this.close = null
      switch (page) {
        case 'about':
          this.$router.push({ name: 'page', params: { page_type: 'about' } })
          break
        case 'howto':
          this.$router.push({ name: 'page', params: { page_type: 'howto' } })
          break
        case 'home':
          this.onClearSearch()
          this.$router.push({ name: 'home' }).catch(() => {})
          break
        case 'news':
          this.$router.push({ name: 'news' })
          break
        case 'login':
          this.$router.push({ name: 'login' })
          break
        case 'logout':
          this.$router.push({ name: 'logout' })
          break
        case 'register':
          this.$router.push({ name: 'register' })
          break
        case 'user-show':
          this.$router.push({
            name: 'user-show',
            params: { id: this.$store.getters.getLoggedInUser.id }
          })
          break
        default:
      }
    }
  }
}
</script>

<style scoped>
header.sp nav {
  height: 2000px;
}
.slide-enter-active {
  transition: all 0.5s ease;
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter, .slide-leave-to
/* .slide-leave-active below version 2.1.8 */ {
  transform: translateY(-2000px);
}
</style>
