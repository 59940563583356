import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  pageviews: [],
  hasNextPage: false,
  endCursor: null,
  filter: {
    start_date: null,
    end_date: null
  },
  sortOrder: 'view_count.desc'
}

export const mutations = {
  SET_PAGEVIEWS (state, pageviews) {
    state.pageviews = pageviews
  },
  SORT_PAGEVIEWS (state, sortFunc) {
    const localPageviews = state.pageviews
    localPageviews.sort(sortFunc)
    state.pageviews = localPageviews
  },
  ADD_PAGEVIEWS (state, pageviews) {
    state.pageviews = state.pageviews.concat(pageviews)
  },
  SET_HAS_NEXT_PAGE (state, hasNextPage) {
    state.hasNextPage = hasNextPage
  },
  SET_END_CURSOR (state, endCursor) {
    state.endCursor = endCursor
  },
  SET_SORT_ORDER (state, sortOrder) {
    state.sortOrder = sortOrder
  }
}

export const actions = {
  fetchPageviews ({ commit, dispatch, state }, args) {
    return GraphQLService.getPageviews(args)
      .then(response => {
        if (args.add) {
          commit('ADD_PAGEVIEWS', response.data.data.pageviews.edges)
        } else {
          commit('SET_PAGEVIEWS', response.data.data.pageviews.edges)
        }
        commit('SET_HAS_NEXT_PAGE', response.data.data.pageviews.pageInfo.hasNextPage)
        commit('SET_END_CURSOR', response.data.data.pageviews.pageInfo.endCursor)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching pageviews: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  setSortOrder ({ commit, dispatch, state }, sortOrder) {
    commit('SET_SORT_ORDER', sortOrder)
  },
  sortPageviews ({ commit, dispatch, state }) {
    var localPageviews = state.pageviews
    switch (state.sortOrder) {
      case 'displayed.desc':
        localPageviews.sort(function (a, b) {
          return ((a.node.displayed === b.node.displayed) ? 0 : ((a.node.displayed < b.node.displayed) ? 1 : -1))
        })
        commit('SET_PAGEVIEWS', localPageviews)
        break
      case 'displayed.asc':
        localPageviews.sort(function (a, b) {
          return ((a.node.displayed === b.node.displayed) ? 0 : ((a.node.displayed > b.node.displayed) ? 1 : -1))
        })
        commit('SET_PAGEVIEWS', localPageviews)
        break
      case 'category.asc':
        localPageviews.sort(function (a, b) {
          return ((a.node.category === b.node.category) ? 0 : ((a.node.category < b.node.category) ? 1 : -1))
        })
        commit('SET_PAGEVIEWS', localPageviews)
        break
      case 'view_count.desc':
        localPageviews.sort(function (a, b) {
          return ((a.node.view_count === b.node.view_count) ? 0 : ((a.node.view_count < b.node.view_count) ? 1 : -1))
        })
        commit('SET_PAGEVIEWS', localPageviews)
        break
      case 'view_count.asc':
        localPageviews.sort(function (a, b) {
          return ((a.node.view_count === b.node.view_count) ? 0 : ((a.node.view_count > b.node.view_count) ? 1 : -1))
        })
        commit('SET_PAGEVIEWS', localPageviews)
        break
    }
  }
}
export const getters = {}
