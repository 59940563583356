import Vue from 'vue'
import Vuex from 'vuex'
import GraphQLService from '@/services/GraphQLService.js'
import * as blog from '@/store/modules/blog.js'
import * as contact from '@/store/modules/contact.js'
import * as episode from '@/store/modules/episode.js'
import * as episodeComment from '@/store/modules/episode-comment.js'
import * as history from '@/store/modules/history.js'
import * as historyComment from '@/store/modules/history-comment.js'
import * as news from '@/store/modules/news.js'
import * as notification from '@/store/modules/notification.js'
import * as page from '@/store/modules/page.js'
import * as pageview from '@/store/modules/pageview.js'
import * as tag from '@/store/modules/tag.js'
import * as timetable from '@/store/modules/timetable.js'
import * as timetableComment from '@/store/modules/timetable-comment.js'
import * as user from '@/store/modules/user.js'
import * as yearAffair from '@/store/modules/yearAffair.js'
import { getApiClient } from '@/helpers/request-options.js'

Vue.use(Vuex)

const apiClient = getApiClient()

export default new Vuex.Store({
  modules: {
    blog,
    contact,
    episode,
    episodeComment,
    history,
    historyComment,
    news,
    notification,
    page,
    pageview,
    tag,
    timetable,
    timetableComment,
    user,
    yearAffair
  },
  state: {
    token: null,
    loggedInUser: null,
    createUser: null,
    unreadNewsCount: 0,
    unreadMessageCount: 0,
    lastActive: null,
    lastStatusUpdate: null
  },
  mutations: {
    SET_TOKEN (state, data) {
      state.token = data
      localStorage.setItem('user', JSON.stringify(data))
      apiClient.defaults.headers.common.Authorization = `Bearer ${data.token}`
    },
    CLEAR_TOKEN (state) {
      state.token = null
      localStorage.removeItem('user')
    },
    SET_LOGGED_IN_USER (state, user) {
      state.loggedInUser = user
    },
    CLEAR_LOGGED_IN_USER (state) {
      state.loggedInUser = null
    },
    SET_CREATE_USER (state, user) {
      state.createUser = user
    },
    CLEAR_CREATE_USER (state) {
      state.createUser = null
    },
    SET_UNREAD_NEWS_COUNT (state, count) {
      state.unreadNewsCount = count
    },
    SET_UNREAD_MESSAGE_COUNT (state, count) {
      state.unreadMessageCount = count
    },
    SET_LAST_ACTIVE (state, lastActive) {
      state.lastActive = lastActive
    },
    SET_LAST_STATUS_UPDATE (state, lastStatusUpdate) {
      state.lastStatusUpdate = lastStatusUpdate
    }
  },
  actions: {
    send ({ commit }, credentials) {
      return apiClient
        .post('/send', credentials)
    },
    check ({ commit }, credentials) {
      return apiClient
        .post('/check', credentials)
        .then(({ data }) => {
          return data
        })
    },
    confirmUser ({ commit }, credentials) {
      commit('SET_CREATE_USER', credentials)
      return true
    },
    create ({ commit }, credentials) {
      return apiClient
        .post('/create', credentials)
        .then(({ data }) => {
          commit('CLEAR_CREATE_USER')
          commit('SET_TOKEN', data)

          GraphQLService.getUser({})
            .then(response => {
              commit('SET_LOGGED_IN_USER', response.data.data.user)
              return response.data.data.user
            })
        })
    },
    login ({ commit }, credentials) {
      return apiClient
        .post('/api/login', credentials)
        .then(({ data }) => {
          commit('SET_TOKEN', data)

          GraphQLService.getUser({})
            .then(response => {
              commit('SET_LOGGED_IN_USER', response.data.data.user)
              return response.data.data.user
            })
        })
    },
    refreshToken ({ commit }) {
      return apiClient
        .post('/api/token/refresh', { refresh_token: this.getRefreshToken })
        .then(({ data }) => {
          commit('SET_TOKEN', data)

          GraphQLService.getUser({})
            .then(response => {
              commit('SET_LOGGED_IN_USER', response.data.data.user)
              return response.data.data.user
            })
        })
    },
    updateLoggedInUser ({ dispatch, commit }) {
      GraphQLService.getUser({})
        .then(response => {
          if (!response.data.data.user || response.data.data.user === null) {
            commit('CLEAR_TOKEN')
            commit('CLEAR_LOGGED_IN_USER')
            commit('CLEAR_LOGGED_IN_USER')
          } else {
            commit('SET_LOGGED_IN_USER', response.data.data.user)
          }
          return response.data.data.user
        })
    },
    logout ({ commit }) {
      commit('CLEAR_TOKEN')
      commit('CLEAR_LOGGED_IN_USER')
    },
    clearLoginState ({ commit }) {
      commit('CLEAR_TOKEN')
      commit('CLEAR_LOGGED_IN_USER')
    },
    reminder ({ commit }, credentials) {
      return apiClient
        .post('/reminder', credentials)
        .then(({ data }) => {
          commit('CLEAR_TOKEN')
          commit('CLEAR_LOGGED_IN_USER')
        })
    },
    resetPassword ({ commit }, credentials) {
      return apiClient
        .post('/reset/password', credentials)
        .then(({ data }) => {
          commit('SET_TOKEN', data)

          GraphQLService.getUser({})
            .then(response => {
              commit('SET_LOGGED_IN_USER', response.data.data.user)
              return response.data.data.user
            })
        })
    },
    getUnreadNewsCount ({ commit }) {
      return GraphQLService.getUnreadNewsCount()
        .then(response => {
          commit('SET_UNREAD_NEWS_COUNT', response.data.data.unreadNewsCount.count)
          return response.data.data.unreadNewsCount.count
        })
    },
    updateLastNewsViewed ({ commit }) {
      return GraphQLService.updateLastNewsViewed()
    },
    setUnreadNewsCount ({ commit }, count) {
      commit('SET_UNREAD_NEWS_COUNT', count)
    },
    getUnreadMessageCount ({ commit }) {
      return GraphQLService.getUnreadMessageCount()
        .then(response => {
          commit('SET_UNREAD_MESSAGE_COUNT', response.data.data.unreadMessageCount.count)
          return response.data.data.unreadMessageCount.count
        })
    },
    updateLastActive ({ commit, state }) {
      commit('SET_LAST_ACTIVE', +new Date())
    },
    updateLastStatusUpdate ({ commit, state }) {
      commit('SET_LAST_STATUS_UPDATE', +new Date())
    }
  },
  getters: {
    loggedIn (state) {
      return !!state.token
    },
    isOwner: state => id => {
      return (state.loggedInUser && state.loggedInUser.id === id)
    },
    getRefreshToken (state) {
      return state.token.refresh_token
    },
    getLoggedInUser (state) {
      return state.loggedInUser
    },
    getCreateUser (state) {
      return state.createUser
    },
    getUnreadNewsCount (state) {
      return state.unreadNewsCount
    },
    getUnreadMessageCount (state) {
      return state.unreadMessageCount
    },
    getLastStatusUpdate (state) {
      return state.lastStatusUpdate
    }
  }
})
