import { getApiClient } from '@/helpers/request-options.js'

const apiClient = getApiClient()

export default {
  updateUser (user) {
    return apiClient.put('/api/user', user)
  },
  uploadImage (file) {
    var formData = new FormData()
    formData.append('file', file)

    return apiClient.post('/api/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
