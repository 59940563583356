import GraphQLService from '@/services/GraphQLService.js'

export const namespaced = true

export const state = {
  tags: [],
  tagsTotal: 0,
  tag: {},
  perPage: 10
}

export const mutations = {
  SET_TAGS (state, tags) {
    state.tags = tags
  },
  SET_TAGS_TOTAL (state, tagsTotal) {
    state.tagsTotal = tagsTotal
  }
}

export const actions = {
  fetchTags ({ commit, dispatch, state }, { page }) {
    return GraphQLService.getTags(state.perPage, page)
      .then(response => {
        commit(
          'SET_TAGS_TOTAL',
          parseInt(response.headers['x-total-count'])
        )
        commit('SET_TAGS', response.data.data.tags)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching tags: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  }
}
