<template>
  <!-- 共通ヘッダ PC TAB -->
  <header class="pc tab">
    <div class="inner">
      <h1>
        <a @click="onSiteTop">
          <img
            src="/img/logo.png"
            alt="TIME CAPSULE あなたの物語が歴史の1ページになる"
          >
        </a>
      </h1>
      <nav>
        <ul class="about">
          <li>
            <router-link :to="{ name: 'page', params: { page_type: 'about' } }">
              タイムカプセルについて
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'page', params: { page_type: 'howto' } }">
              使い方
            </router-link>
          </li>
        </ul>
        <ul class="user">
          <li>
            <router-link :to="{ name: 'news' }">
              お知らせ<template
                v-if="getLoggedInUser && getUnreadNewsCount > 0"
              >
                <span class="unread">{{ getUnreadNewsCount }}</span>
              </template>
            </router-link>
          </li>
          <li>
            <router-link v-if="!loggedIn" :to="{ name: 'login' }">
              ログイン
            </router-link>
            <router-link v-else :to="{ name: 'logout' }">
              ログアウト
            </router-link>
          </li>
          <template v-if="!getLoggedInUser || getLoggedInUser === null">
            <li>
              <router-link :to="{ name: 'register' }">
                会員登録
              </router-link>
            </li>
          </template>
          <template v-else>
            <li class="profile" :class="getLoggedInUser.profile_color">
              <router-link
                :to="{ name: 'user-show', params: { id: getLoggedInUser.id } }"
              >
                <BaseUserImg :user="getLoggedInUser" />
                <span>マイページ</span>
              </router-link>
            </li>
            <li class="edit">
              <router-link :to="{ name: 'episode-create' }">
                記事を書く
              </router-link>
            </li>
          </template>
        </ul>
        <div id="search-app" class="searchBox">
          <input
            v-if="hasSearch"
            type="reset"
            value="×"
            @click="onClearSearch"
          >
          <input
            v-model="searchBox"
            type="text"
            placeholder="エピソードを検索"
            @blur="onBlur"
            @keyup="onKeyup"
          >
          <button>
            <img src="/img/ic_search.png" alt="検索">
          </button>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import store from '@/store/index'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  data () {
    return {
      searchBox: null,
      doNotRefetch: false
    }
  },
  computed: {
    ...authComputed,
    ...mapState(['episode']),
    ...mapGetters({
      getSearchBox: 'episode/getSearchBox',
      getUnreadNewsCount: 'getUnreadNewsCount',
      getUnreadMessageCount: 'getUnreadMessageCount'
    }),
    hasSearch () {
      if (this.getSearchBox) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('episode', ['SET_SEARCH_BOX']),
    logout (event) {
      event.preventDefault()
      this.$router.push({ name: 'logout' }).catch(() => {})
    },
    onSiteTop: function () {
      store.dispatch('episode/setUser', null).then(() => {
        this.onClearSearch()
        this.$router.push({ name: 'home' }).catch(() => {})
      })
    },
    onKeyup: function (e) {
      if (e.keyCode === 13 && this.searchBox) {
        this.onBlur(e)
      }
    },
    onBlur: function () {
      var val = this.searchBox.replace(/　/g, " "); // eslint-disable-line
      this.SET_SEARCH_BOX(val)
      const search = val.split(' ')
      store.dispatch('episode/setSearch', { search }).then(() => {
        const args = { first: 12, add: false }
        store.dispatch('episode/fetchEpisodes', args).then(() => {
          this.$router.push({ name: 'home' }).catch(() => {})
        })
      })
    },
    onClearSearch: function () {
      this.searchBox = ''
      const search = []
      store.dispatch('episode/setSearch', { search }).then(() => {
        const args = { first: 12, add: false }
        store.dispatch('episode/fetchEpisodes', args)
      })
    },
    onSelectTag: function (tag) {
      this.doNotRefetch = true
      this.searchBox = tag.name
    }
  }
}
</script>
